
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  


  
  var importedApp = {};


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://8d3d495371314e019c7c51a0f4c0c90d@sentry.wixpress.com/6021',
      id: 'undefined',
      projectName: 'hisense-stores',
      teamName: 'hisense-dev-team',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/2dad49df40ef8bed/packages/hisense-stores/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/2dad49df40ef8bed/packages/hisense-stores/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "hisense-stores",
    biConfig: null,
    appName: "hisense-stores",
    appDefinitionId: "14932f7b-636f-4893-aae2-8db73e65635a",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["test"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/2dad49df40ef8bed/packages/hisense-stores/src/components/GalleryCtas/controller.ts",
          appName: "hisense-stores",
          appDefinitionId: "14932f7b-636f-4893-aae2-8db73e65635a",
          projectName: "hisense-stores",
          componentName: "GalleryCtas",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "0fbe5b50-debe-428a-bf81-e0276b360991" }, { method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["test"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/2dad49df40ef8bed/packages/hisense-stores/src/components/ProductCtas/controller.ts",
          appName: "hisense-stores",
          appDefinitionId: "14932f7b-636f-4893-aae2-8db73e65635a",
          projectName: "hisense-stores",
          componentName: "ProductCtas",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "a2f9703a-cd98-4d34-b395-2ff006c59a42" }],
    false);

    export const createControllers = _createControllers
